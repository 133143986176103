import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { IValidationResult } from 'modules/password/models/password-policies.model';
import { PasswordPoliciesService } from 'modules/password/services/password-policies.service';
import { IUser } from 'modules/user/models/user.model';
import { Subject, catchError, debounceTime, map, of } from 'rxjs';

@Component({
  selector: 'password-input',
  templateUrl: './password-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PasswordInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PasswordInputComponent),
      multi: true,
    },
  ],
})
export class PasswordInputComponent implements OnInit, ControlValueAccessor, Validator {
  @Input() user: Partial<IUser>;
  @Input() submitted: boolean;
  @Input() showOnlyFailedPolicies = false;

  rules: any[];
  validatedRules: IValidationResult;
  value = '';
  passwordDirty = false;

  private onChange: (v: any) => void;
  private onTouched: () => void;
  private validatorChange: () => void;

  private passwordChange$ = new Subject<string>();

  constructor(private passwordPoliciesService: PasswordPoliciesService) {}

  onRulesChange(updatedRules: any[]) {
    this.rules = updatedRules; // Update the rules in the parent component
  }

  ngOnInit() {
    this.passwordChange$.pipe(debounceTime(500)).subscribe(() => {
      if (this.rules && this.user.password) {
        this.passwordChanged();
      }
    });
  }

  // Called when the ngModel is updated
  onPasswordChange(password: string): void {
    this.value = password;
    this.passwordDirty = true; // Update the dirty state
    this.onTouched();
    this.onChange(password);
    this.validatorChange();
    this.passwordChange$.next(password);
  }

  passwordChanged() {
    const validatedRules = this.passwordPoliciesService.preValidatePasswordRules(
      this.rules,
      this.user.password,
      this.user,
    );

    return this.passwordPoliciesService
      .checkPasswordRules(this.user.password, this.user, true)
      .pipe(
        map((rules) => {
          // Merge failed and completed rules using native Set to remove duplicates
          validatedRules.failedRules = [...new Set([...validatedRules.failedRules, ...rules.failedRules])];
          validatedRules.completedRules = [...new Set([...validatedRules.completedRules, ...rules.completedRules])];

          this.validatedRules = validatedRules;
          this.validatorChange();

          // Return validation result based on the number of failed rules
          if (this.validatedRules.failedRules.length) {
            throw new Error('Validation failed'); // Trigger error flow
          }

          return of(null);
        }),
        catchError(() => of(null)),
      )
      .subscribe();
  }

  writeValue(value: any): void {
    this.value = value;
    // Optionally update the view
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  registerOnValidatorChange(fn: () => void): void {
    this.validatorChange = fn;
  }

  validate(): ValidationErrors | null {
    const errors: ValidationErrors = {};

    if (!this.value || this.value.trim() === '') {
      errors['required'] = true;
    }

    if (this.user?.oldPassword && this.user.oldPassword === this.value) {
      errors['sameAsOld'] = true;
    }

    if (this.validatedRules?.failedRules?.length > 0) {
      errors['passwordPolicy'] = true;
    }

    return Object.keys(errors).length ? errors : null;
  }
}
