import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IUserNetworkEntity } from 'modules/network/models/user-network-activity.model';
import { UserNetworkActivityService } from 'modules/network/services/user-network-activity.service';
import { UserNetworkCommentsService } from 'modules/network/services/user-network-comments.service';
import { EMPTY, Unsubscribable, catchError, finalize, tap } from 'rxjs';

@Component({
  selector: 'edit-thread-reply-modal',
  templateUrl: './edit-thread-reply-modal.component.html',
})
export class EditThreadReplyModalComponent implements OnDestroy {
  savePromise = false;
  activity: IUserNetworkEntity;
  error: string;

  private requestSubscriber?: Unsubscribable;

  constructor(
    private activeModal: NgbActiveModal,
    private userNetworkCommentsService: UserNetworkCommentsService,
    private userNetworkActivityService: UserNetworkActivityService,
  ) {}

  ngOnDestroy(): void {
    this.clearRequestSubscriber();
  }

  cancel(): void {
    this.activeModal.dismiss();
  }

  submit(): void {
    this.savePromise = true;
    let promise;
    const draft = Object.assign({}, this.activity, { text: this.activity.text.trim() });

    if (this.activity.id) {
      promise = this.userNetworkCommentsService.update(draft, true);
    } else {
      promise = this.userNetworkActivityService.addComment(this.activity.activityId, draft, true);
    }

    this.requestSubscriber = promise
      .pipe(
        tap((result) => this.activeModal.close(result)),
        catchError((e: HttpErrorResponse) => this.handleError(e)),
        finalize(() => this.clearRequestSubscriber()),
      )
      .subscribe();
  }

  private handleError(e: HttpErrorResponse) {
    this.error = e.error;

    return EMPTY;
  }

  private clearRequestSubscriber(): void {
    this.savePromise = false;

    if (this.requestSubscriber) {
      this.requestSubscriber.unsubscribe();
      delete this.requestSubscriber;
    }
  }
}
