import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserPasswordService } from 'modules/password/services/user-password.service';
import { IUser } from 'modules/user/models/user.model';
import { EMPTY, Unsubscribable, catchError, finalize, tap } from 'rxjs';

export interface IResolveResetPasswordModal {
  user: IUser;
}

@Component({
  selector: 'reset-password-modal',
  templateUrl: './reset-password-dialog.component.html',
})
export class ResetPasswordModalComponent {
  @Input() resolve!: IResolveResetPasswordModal;

  user: any;
  error: string | null = null;
  resetPasswordPromise: Unsubscribable;

  constructor(
    private userPasswordService: UserPasswordService,
    private activeModal: NgbActiveModal,
  ) {}

  resetPassword() {
    this.resetPasswordPromise = this.userPasswordService
      .resetPassword(this.user.id, true)
      .pipe(
        tap(() => {
          this.activeModal.close();
        }),
        catchError((reason: HttpErrorResponse) => {
          this.error = reason.error;

          return EMPTY;
        }),
        finalize(() => {
          if (this.resetPasswordPromise) {
            this.resetPasswordPromise.unsubscribe();
            delete this.resetPasswordPromise;
          }
        }),
      )
      .subscribe();
  }

  cancelModal(): void {
    this.activeModal.dismiss();
  }
}
