import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { StateService } from '@uirouter/core';
import { GlobalConfig } from 'core/environment';
import { BackUrlService } from 'core/navigation/back-url.service.ajs-upgraded-provider';
import { UserPasswordService } from 'modules/password/services/user-password.service';
import { IUser } from 'modules/user/models/user.model';
import { Unsubscribable, catchError, finalize, of, tap } from 'rxjs';

@Component({
  selector: 'app-password-assistance',
  templateUrl: './password-assistance.component.html',
})
export class PasswordAssistanceComponent implements OnInit, OnDestroy {
  @Input() token: string;

  signInWording: any;
  user: Partial<IUser>;
  status: string | null = null;
  error: string | null = null;
  submitted = false;
  helpPageExists = false;
  supportPageExists = false;
  passwordConfirmationValue: string;
  userSecuritySubscriber: Unsubscribable;

  constructor(
    private userPasswordService: UserPasswordService,
    private stateService: StateService,
    private backUrlService: BackUrlService,
    private globalConfigService: GlobalConfig,
  ) {
    this.signInWording = this.globalConfigService.signInWording;
    this.user = { loginName: '', firstName: '', password: null };
  }

  ngOnInit(): void {
    this.userSecuritySubscriber = this.userPasswordService
      .getTokenResult(this.token)
      .pipe(
        tap((data) => {
          this.status = data.tokenStatus;

          if (data.user) {
            Object.assign(this.user, data.user);
          }
        }),
        finalize(() => {
          this.clearSubscriber();
        }),
      )
      .subscribe();

    this.helpPageExists = !!this.stateService.get('main.help');
    this.supportPageExists = !this.helpPageExists && !!this.stateService.get('main.support');
  }

  ngOnDestroy(): void {
    this.clearSubscriber();
  }

  submit(): void {
    this.submitted = true;

    this.userSecuritySubscriber = this.userPasswordService
      .changePasswordByToken(this.token, this.user.password, true)
      .pipe(
        tap((data) => {
          this.status = data.result;
          this.error = null;
        }),
        catchError((reason: HttpErrorResponse) => {
          this.error = reason.error;

          return of(null);
        }),
        finalize(() => {
          this.clearSubscriber();
        }),
      )
      .subscribe();
  }

  redirectBack(): void {
    this.backUrlService.goBack();
  }

  next(): void {
    this.stateService.go('prompt.forgotPassword');
  }

  goToLogin(): void {
    this.stateService.go('prompt.login');
  }

  get helpLink(): string {
    return this.stateService.href('main.help');
  }

  get supportLink(): string {
    return this.stateService.href('main.support');
  }

  private clearSubscriber(): void {
    if (this.userSecuritySubscriber) {
      this.userSecuritySubscriber.unsubscribe();
      delete this.userSecuritySubscriber;
    }
  }
}
