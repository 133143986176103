import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { IActionItem } from 'modules/navigation/models/navigation.model';

abstract class Behavior {
  expanded = false;

  constructor(protected options: { shortSize: number; items: any[] }) {}

  abstract couldDecrease(): boolean;

  abstract couldExpand(): boolean;

  abstract showMore(): void;

  abstract showLess(): void;

  abstract showLimit(): number;
}

class PCScreenBehaviour extends Behavior {
  couldDecrease(): boolean {
    return this.options.shortSize > 0 && this.expanded;
  }

  couldExpand(): boolean {
    return this.options.shortSize > 0 && !this.expanded && this.options.items.length > this.options.shortSize;
  }

  showMore(): void {
    this.expanded = true;
  }

  showLess(): void {
    this.expanded = false;
  }

  showLimit(): number {
    return this.options.shortSize;
  }
}

class MobileScreenBehaviour extends Behavior {
  shownItems = this.options.shortSize > 0 ? this.options.shortSize : 4;

  couldDecrease(): boolean {
    return this.options.shortSize > 0 && this.expanded;
  }

  couldExpand(): boolean {
    return (
      this.options.shortSize > 0 &&
      !this.expanded &&
      this.options.items.length > this.options.shortSize &&
      this.shownItems < this.options.items.length
    );
  }

  showMore(): void {
    this.shownItems += this.options.shortSize;
    this.expanded = this.shownItems >= this.options.items.length;
  }

  showLess(): void {
    this.shownItems = this.options.shortSize;
    this.expanded = false;
  }

  showLimit(): number {
    return this.shownItems;
  }
}

@Component({
  selector: 'navigation-tiles',
  templateUrl: './navigation-tiles.component.html',
})
export class NavigationTilesComponent implements OnInit {
  static readonly selector = 'navigationTiles';

  @Input() items: IActionItem[] = [];
  @Input() shortSize: number;
  @Input() shortSizeMobile: number;
  @Output() itemClicked = new EventEmitter<IActionItem>();
  private behavior: Behavior;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.choseStrategy();
  }

  ngOnInit() {
    this.validateRowsAndCols();
    this.choseStrategy();
  }

  couldDecrease(): boolean {
    return this.behavior.couldDecrease();
  }

  couldExpand(): boolean {
    return this.behavior.couldExpand();
  }

  called(item: IActionItem) {
    this.itemClicked.emit(item);
  }

  toggleExpand($event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();

    if (this.behavior.couldExpand()) {
      this.behavior.showMore();
    } else {
      this.behavior.showLess();
      this.updateScrollPosition($event);
    }
  }

  showLimit(): number {
    return this.behavior.showLimit();
  }

  get shownItems() {
    return this.couldExpand() ? this.items.slice(0, this.showLimit()) : this.items;
  }

  private updateScrollPosition($event: MouseEvent) {
    const focusedEl = (<HTMLElement>$event.target).closest('.focus_target');

    requestAnimationFrame(() => {
      setTimeout(() => {
        if (focusedEl && focusedEl.getBoundingClientRect().top < window.innerHeight / 4) {
          focusedEl.scrollIntoView();
          document.documentElement.scrollTop -= window.innerHeight / 4;
        }
      });
    });
  }

  private choseStrategy() {
    this.behavior =
      window.innerWidth < 768
        ? new MobileScreenBehaviour({ shortSize: this.shortSizeMobile, items: this.items })
        : new PCScreenBehaviour({ shortSize: this.shortSize, items: this.items });
  }

  private validateRowsAndCols() {
    this.shortSize = this.shortSize ? (this.shortSize > 0 ? this.shortSize : 0) : 0;
    this.shortSizeMobile = this.shortSizeMobile ? (this.shortSizeMobile > 0 ? this.shortSizeMobile : 0) : 0;
  }
}
