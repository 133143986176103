import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { StateService } from '@uirouter/core';
import { ElmsAuthService } from 'ajs/modules/app/authorization/elms-auth-service';
import { CurrentUserService } from 'core/authorization';
import { GlobalConfig } from 'core/environment';
import { NavigationService } from 'core/navigation';
import { LocationHistoryService } from 'core/navigation/location-history.service.ajs-upgraded-provider';

@Component({
  selector: 'password-login',
  templateUrl: './password-login.component.html',
})
export class PasswordLoginComponent implements OnInit {
  @Input() email: string;
  @Input() nextTargetUrl: string;
  @Input() stage = 'ready';
  @Input() showLabels = false;
  @Input() showResetPassword = false;
  @Input() independentValidation = false;

  formSubmitted = false;
  signInWording = this.globalConfig.signInWording;
  credentials = {
    username: '',
    password: '',
  };

  loginError = '';
  submitDisabled = true;
  loginConfig: any;
  currentUser: any;
  targetPath: string;

  constructor(
    private stateService: StateService,
    private navigationService: NavigationService,
    private locationHistory: LocationHistoryService,
    private elmsAuthService: ElmsAuthService,
    private globalConfig: GlobalConfig,
    private currentUserService: CurrentUserService,
  ) {}

  ngOnInit(): void {
    this.credentials.username = this.email;
    this.loginConfig = this.globalConfig.settings.login || {};
    this.currentUser = this.currentUserService.get();

    if (this.locationHistory.getLength() > 0) {
      const state = this.locationHistory.pop();

      this.navigationService.setTargetState(state);
    }
  }

  login(loginForm: NgForm): void {
    this.formSubmitted = true;

    if (this.stage === 'validation') {
      return;
    }

    if (loginForm.valid) {
      this.loginError = '';
      this.stage = 'validation';
      this.elmsAuthService
        .login(this.credentials)
        .then(() => {
          this.stage = 'submission';
          this.loginHandler(loginForm);
        })
        .catch((error) => {
          this.stage = 'error';
          this.loginError = error.message;
          this.loginErrorHandler(error.message, error.status, error.data);
        });
    } else {
      if (!this.independentValidation) {
        this.loginError = `Please enter your ${this.signInWording.username} and password.`;
      }
    }
  }

  get getForgotPasswordLink() {
    return this.stateService.href('prompt.forgotPassword');
  }

  private loginHandler(loginForm: NgForm): void {
    this.submitDisabled = false;
    const currentUser = this.currentUserService.get();

    if (this.globalConfig.settings.view === 'admin' && !currentUser?.roles?.length) {
      this.targetPath = this.globalConfig.base.replace('/admin', '');
    } else {
      this.targetPath = this.navigationService.getRelativeTargetPath() || this.globalConfig.base;
    }

    loginForm.form.controls['redirectTo'].setValue(this.targetPath);
    loginForm.form.controls['redirectTo'].updateValueAndValidity();

    const nativeForm: HTMLFormElement = document.getElementById('loginForm') as HTMLFormElement;

    if (nativeForm) {
      nativeForm.submit();
    }
  }

  private loginErrorHandler(message: string, status: number, data: any): void {
    if (status === 5 && !!this.stateService.get('main.courseReviews')) {
      this.stateService.go('prompt.resetPassword', { loginName: this.credentials.username });
    }

    if (status === 6 && !!this.stateService.get('main.courseReviews')) {
      this.stateService.go('prompt.forgotPassword', {
        lockTypeId: data ? data.typeId : null,
        accountLocked: true,
        lockedDescription: data ? data.description : null,
      });
    }

    if (status === 7 && !!this.stateService.get('prompt.emailVerificationRequired')) {
      this.stateService.go('prompt.emailVerificationRequired');
    }

    this.loginError = message;
    this.stage = 'error';
  }
}
