import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CurrentUserService } from 'ajs/modules/app/current-user.service';
import { GlobalConfig } from 'core/environment';
import { SecurityService } from 'core/services';
import _ from 'lodash';
import { EditThreadReplyModalComponent } from 'modules/network/edit/modal/edit-thread-reply-modal.component';
import {
  ActivityOrigin,
  IUserNetworkActivity,
  IUserNetworkEntity,
} from 'modules/network/models/user-network-activity.model';
import { NetworkService } from 'modules/network/services/network.service';
import { UserNetworkActivityService } from 'modules/network/services/user-network-activity.service';
import { UserNetworkCommentsService } from 'modules/network/services/user-network-comments.service';
import moment from 'moment';
import { Unsubscribable, finalize } from 'rxjs';

@Component({
  selector: 'network-activity',
  templateUrl: './network-activity.component.html',
})
export class NetworkActivityComponent implements OnInit, OnDestroy {
  @Input() activity: IUserNetworkActivity;
  @Input() isEditable: boolean;
  @Input() query: string;
  @Input() showMarkdown: boolean;
  @Input() activityOrigin: ActivityOrigin;
  @Input() showAvatar: boolean;
  @Input() systemMessagesEnable: boolean;
  @Input() allowLikes: boolean;
  @Input() trackingName: string;

  savePromise?: Unsubscribable;
  modalSubscriber?: Unsubscribable;

  editMode = false;
  selfView = false;
  mobileView = false;

  readonly isStateAvailable = this.securityService.isStateAvailable;

  constructor(
    private globalConfig: GlobalConfig,
    private currentUser: CurrentUserService,
    private userNetworkActivityService: UserNetworkActivityService,
    private userNetworkCommentsService: UserNetworkCommentsService,
    private securityService: SecurityService,
    private ngbModalService: NgbModal,
    private networkService: NetworkService<IUserNetworkActivity>,
    private window: Window,
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.mobileView = this.window.innerWidth < 768;
  }

  ngOnInit() {
    const hasPermissions = this.currentUser.get().checkPermission('network.activity.edit');

    this.selfView = this.activity.user && this.activity.user.id === this.currentUser.get().id;
    this.isEditable = this.isEditable || (this.globalConfig.settings.view === 'admin' && hasPermissions);
    this.networkService.init(this.userNetworkActivityService);
  }

  ngOnDestroy() {
    if (this.savePromise) {
      this.savePromise.unsubscribe();
      delete this.savePromise;
    }

    if (this.modalSubscriber) {
      this.modalSubscriber.unsubscribe();
      delete this.modalSubscriber;
    }
  }

  isEdited(): boolean {
    return this.activity.date.toString() !== this.activity.updated.toString();
  }

  isCourseActivity(): boolean {
    return ['course_review', 'course_registration', 'course_completion', 'course_withdrawal', 'course_post'].includes(
      this.activity.activityType,
    );
  }

  deleteThread() {
    if (this.savePromise) {
      return;
    }

    const draft = Object.assign({}, this.activity);

    draft.deleted = true;
    this.savePromise = this.userNetworkActivityService
      .update(draft)
      .pipe(
        finalize(() => {
          this.savePromise.unsubscribe();
          delete this.savePromise;
        }),
      )
      .subscribe((activity) => {
        Object.assign(this.activity, activity);
      });
  }

  orderBy(comments: IUserNetworkEntity[], field: string): IUserNetworkEntity[] {
    return _.orderBy(comments, field);
  }

  createReply() {
    const modalReference: NgbModalRef = this.ngbModalService.open(EditThreadReplyModalComponent, {
      backdrop: 'static',
      animation: true,
    });

    (<EditThreadReplyModalComponent>modalReference.componentInstance).activity =
      this.userNetworkCommentsService.newComment({
        activityId: this.activity.id,
        activityOrigin: this.activityOrigin,
      });

    this.modalSubscriber = modalReference.closed
      .pipe(
        finalize(() => {
          this.modalSubscriber.unsubscribe();
          delete this.modalSubscriber;
        }),
      )
      .subscribe((comment) => {
        this.activity.comments.items.push(comment);
        this.activity.comments.count++;
      });
  }

  timeSinceActivity(): string {
    return moment(this.activity.date).fromNow();
  }

  toggleLike() {
    if (this.savePromise) {
      return;
    }

    this.savePromise = this.networkService
      .toggleLike(this.activity)
      .pipe(
        finalize(() => {
          this.savePromise.unsubscribe();
          delete this.savePromise;
        }),
      )
      .subscribe();
  }

  editThread() {
    this.networkService.showEditThreadDialog(this.activity).then(
      (activity) => {
        Object.assign(this.activity, activity);
      },
      () => null,
    );
  }

  commentDeleted(comment) {
    this.activity.comments.count--;
    this.activity.comments.items = _.without(this.activity.comments.items, comment);
  }
}
