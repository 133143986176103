import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { StateService } from '@uirouter/core';
import { GlobalConfig } from 'core/environment';
import { BackUrlService } from 'core/navigation/back-url.service.ajs-upgraded-provider';
import { ISiteSettings } from 'core/site-settings/models/site-settings.model';
import { SiteSettingsService } from 'core/site-settings/services/site-settings.service';
import { IRecoveryResponse } from 'modules/login/models/reset-pasword.models';
import { UserPasswordService } from 'modules/password/services/user-password.service';
import { Unsubscribable, catchError, finalize, of, tap } from 'rxjs';

@Component({
  selector: 'forgot-password',
  templateUrl: './forgot-password.component.html',
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  @Input() accountLocked: boolean;
  @Input() lockTypeId?: string;
  @Input() email?: string;
  @Input() lockedDescription?: string;

  status = 'enter_email';
  helpPageExists = false;
  supportPageExists = false;
  contactsPageExists = false;
  signInWording = this.globalConfig.signInWording;
  appSettings = this.globalConfig.settings;
  emailText = 'user name or email';
  model = {
    userEmail: '',
  };

  supportEmail?: string;
  processingSubscriber: Unsubscribable;
  currentSiteSetting: ISiteSettings;

  constructor(
    private stateService: StateService,
    private userPasswordService: UserPasswordService,
    private backUrlService: BackUrlService,
    private globalConfig: GlobalConfig,
    private siteSettingService: SiteSettingsService,
  ) {}

  async ngOnInit() {
    this.currentSiteSetting = await this.siteSettingService.getCurrent();

    this.contactsPageExists = !!this.stateService.get('main.contacts');
    this.helpPageExists = !this.contactsPageExists && !!this.stateService.get('main.help');
    this.supportPageExists = !this.helpPageExists && !!this.stateService.get('main.support');

    if (this.signInWording.username?.toLowerCase().includes('email')) {
      this.emailText = 'email';
    } else {
      this.emailText = `${this.signInWording.username || 'user name'} or email`;
    }

    this.supportEmail = this.currentSiteSetting.supportEmail || this.appSettings.support.email;
  }

  goBack(): void {
    this.backUrlService.goBackInHistory();
  }

  submit(): void {
    if (this.model.userEmail) {
      this.processingSubscriber = this.userPasswordService
        .recoveryPassword(this.model.userEmail, true)
        .pipe(
          tap((res: IRecoveryResponse) => {
            if (res.result === 0) {
              this.status = 'email_sent';
            } else if (res.result === 2) {
              this.status = 'limit_exceeded';
            } else {
              this.status = 'error';
            }
          }),
          catchError(() => {
            this.status = 'error';

            return of(null);
          }),
          finalize(() => {
            this.clearSubscriber();
          }),
        )
        .subscribe();
    }
  }

  ngOnDestroy() {
    this.clearSubscriber();
  }

  get helpLink(): string {
    return this.stateService.href('main.help');
  }

  get supportLink(): string {
    return this.stateService.href('main.support');
  }

  get contactsLink(): string {
    return this.stateService.href('main.contacts');
  }

  private clearSubscriber(): void {
    if (this.processingSubscriber) {
      this.processingSubscriber.unsubscribe();
      delete this.processingSubscriber;
    }
  }
}
