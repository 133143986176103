import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { GlobalConfig } from 'core/environment';
import { NavigationService } from 'core/navigation';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-corporate-login',
  templateUrl: './corporate-login.component.html',
})
export class CorporateLoginComponent implements OnInit {
  emailValue = '';
  submitDisabled = false;
  targetPath: string | null = null;
  formSubmitted = false;

  constructor(
    private cookieService: CookieService,
    private navigationService: NavigationService,
    private globalConfig: GlobalConfig,
  ) {}

  ngOnInit(): void {
    if (!this.isCorporateLoginTookPlace()) {
      this.emailValue = '';
    }
  }

  isCorporateLoginTookPlace(): boolean {
    return !!this.cookieService.get('SAML_SSO_IDP_ID');
  }

  login(form: NgForm): void {
    this.formSubmitted = true;
    this.targetPath = this.navigationService.getRelativeTargetPath() || this.globalConfig.base;
    this.targetPath = this.targetPath ? btoa(this.targetPath) : null;

    if (form.valid) {
      this.submitDisabled = true;
      this.onCorporateLogin();
    }
  }

  // Method to handle the corporateLogin event
  private onCorporateLogin() {
    const nativeForm: HTMLFormElement = document.getElementById('corporateLoginForm') as HTMLFormElement;

    if (nativeForm) {
      nativeForm.submit();
    }
  }
}
