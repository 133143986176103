import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
  selector: '[passwordMatch]',
  providers: [{ provide: NG_VALIDATORS, useExisting: PasswordMatchDirective, multi: true }],
})
export class PasswordMatchDirective implements Validator, OnChanges {
  @Input('passwordMatch') password: string;
  private onChange: () => void;

  validate(control: AbstractControl): ValidationErrors | null {
    if (control.value !== this.password) {
      return { passwordMismatch: true };
    }

    return null;
  }

  registerOnValidatorChange(fn: () => void): void {
    this.onChange = fn;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('password' in changes) {
      if (this.onChange) {
        this.onChange();
      }
    }
  }
}
